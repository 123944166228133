



















































































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import validator from "validator";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      amount: false,
      loading: false,
      success: false,
      error: false,
      loaded: false,
      dialog: false,
    });

    const model = reactive({
      data: {
        name: null,
        standWidth: null,
        standDepth: null,
        numberOfTables: null,
        numberOfChairs: null,
        accessToElectricity: false,
        power: null,
        attentions: null,
        buildingIsRequired: null,
        buildingCompany: null,
        buildingPhoneNumber: null,
        buildingEmail: null,
      },
    });

    const rules = {
      phoneNumber: [
        (v: string) =>
          !v ||
          validator.isMobilePhone(v, ["pl-PL"]) ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
      phoneNumberBasic: [
        (v: string) =>
          !v ||
          (v.length >= 9 && validator.isNumeric(v, { no_symbols: true })) ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`company/${root.$route.params.cid}`)
        .then(({ data: { company } }) => {
          model.data.name = company.name;
          model.data.standWidth = company.standWidth || null;
          model.data.standDepth = company.standDepth || null;
          model.data.numberOfTables = company.numberOfTables || null;
          model.data.numberOfChairs = company.numberOfChairs || null;
          model.data.accessToElectricity = company.accessToElectricity || null;
          model.data.power = company.power || null;
          model.data.attentions = company.attentions || null;
          model.data.buildingIsRequired = company.buildingIsRequired || null;
          model.data.buildingCompany = company.buildingCompany || null;
          model.data.buildingPhoneNumber = company.buildingPhoneNumber || null;
          model.data.buildingEmail = company.buildingEmail || null;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.data.name,
        standWidth: model.data.standWidth || null,
        standDepth: model.data.standDepth || null,
        numberOfTables: model.data.numberOfTables || null,
        numberOfChairs: model.data.numberOfChairs || null,
        accessToElectricity: model.data.accessToElectricity || undefined,
        power: model.data.power || null,
        attentions: model.data.attentions || null,

        buildingIsRequired: model.data.buildingIsRequired || null,
        buildingCompany: model.data.buildingCompany || null,
        buildingPhoneNumber: model.data.buildingPhoneNumber || null,
        buildingEmail: model.data.buildingEmail || null,
      };

      state.loading = true;

      axiosInstance
        .put(`company/${root.$route.params.cid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.dialog = true;
          state.error = false;
        })
        .catch((error) => {
          state.error = error.response.status;
          state.dialog = true;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.standExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    return { state, model, rules, onSubmit, getErrorMessage };
  },
});
